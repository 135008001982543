import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'

import {
    Box,
    Button,
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'

import './hero-carousel.scss'

import HeroSection from '.'
import {NextArrowIcon, PreviousArrowIcon} from '../icons'

const NextArrow = (props) => (
    <Button
        className="home_carousel_arrow home_carousel_arrow--next"
        onClick={props?.onClick}
    >
        <NextArrowIcon />
    </Button>
)

const PrevArrow = (props) => (
    <Button
        className="home_carousel_arrow home_carousel_arrow--previous"
        onClick={props?.onClick}
    >
        <PreviousArrowIcon />
    </Button>
)

NextArrow.propTypes = {
    onClick: PropTypes.func
}

PrevArrow.propTypes = {
    onClick: PropTypes.func
}

const HeroImageSlider = ({carousel}) => {
    const isArrow = useBreakpointValue({base: false, lg: true})
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dotsClass: 'slick-dots-home',
        className: 'slick-container',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        isArrow
    }

    const items = carousel?.items

    if (items.length === 1)
        return (
            <Box position="relative" width="100vw">
                <HeroSection data={_.head(items)} />
            </Box>
        )

    return (
        !!items?.length && (
            <Box position="relative" width="100vw">
                <Slider {...settings}>
                    {_.map(
                        items.slice(
                            0,
                            carousel?.maxNumberOfItems || items.length
                        ),
                        ({fields}, index) => (
                            <div key={index}>
                                <HeroSection data={fields} />
                            </div>
                        )
                    )}
                </Slider>
            </Box>
        )
    )
}

const mediaPropType = PropTypes.shape({
    typeName: PropTypes.string.isRequired,
    sys: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    blur: PropTypes.string,
    desktopRatio: PropTypes.string,
    mobileRatio: PropTypes.string,
    position: PropTypes.string,
    desktop: PropTypes.shape({
        typeName: PropTypes.string.isRequired,
        sys: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        contentType: PropTypes.string.isRequired
    }),
    mobile: PropTypes.shape({
        typeName: PropTypes.string.isRequired,
        sys: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        contentType: PropTypes.string.isRequired
    })
})

const ctaPropType = PropTypes.shape({
    sys: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    __typename: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    externalUrl: PropTypes.string,
    openLinkInNewTab: PropTypes.bool,
    iconName: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
    active: PropTypes.bool,
    actionType: PropTypes.string,
    isExternal: PropTypes.bool
})

const itemPropType = PropTypes.shape({
    sectionName: PropTypes.string.isRequired,
    heading: PropTypes.string,
    subTitle: PropTypes.string,
    mediaAlignment: PropTypes.string,
    ctaTop: ctaPropType,
    media: mediaPropType
})

const itemsCollectionPropType = PropTypes.shape({
    items: PropTypes.arrayOf(itemPropType).isRequired
})

const mainPropType = PropTypes.shape({
    heading: PropTypes.string,
    headingType: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.any,
    theme: PropTypes.string,
    itemsCollection: itemsCollectionPropType,
    maxNumberOfItems: PropTypes.number
})

HeroImageSlider.propTypes = {
    carousel: mainPropType
}

export default HeroImageSlider
