import React from 'react'

const langTexts = {
    fr: {
        description:
            "Rennaï est une boutique de soins de bien-être et de beauté située au Royalmount à Montréal, Canada. Chez Rennaï, nous nous engageons à aider les individus à atteindre le bien-être par le biais de soins personnels grâce à une approche 360° dans un environnement très accessible. Inspiré par le mot français Renaissance, qui signifie renaissance et renouveau, nous nous engageons à aider les personnes à prendre soin d'elles-mêmes, à s'aimer et à se découvrir.",
        url: 'https://www.rennai.com/fr'
    },
    en: {
        description:
            'Rennai is a Self-Care and Beauty Boutique at Royalmount in Montréal, Canada. At Rennaï, we are dedicated to helping individuals achieve well-being through 360° self-care in a highly accessible setting. Inspired by the French word Renaissance, meaning rebirth and renewal, we are committed to empowering individuals on their journey towards self-care, self-love and self-discovery.',
        url: 'https://www.rennai.com/'
    }
}

function StructuredData() {
    const generateSchema = (lang) => {
        const text = langTexts[lang] ?? langTexts['en']
        return {
            '@context': 'http://schema.org',
            '@type': 'OnlineStore',
            name: 'Rennaï',
            alternateName: 'Rennai',
            description: text?.description,
            url: text?.url,
            foundingDate: '2024',
            logo: 'https://images.ctfassets.net/v0rdkyiyx25c/2owwXvAfOLlnQnGWP8Amg4/f8d2e23924bc3f3beab99bed49d6df16/Rennai_Logo1.png',
            address: {
                '@type': 'PostalAddress',
                addressCountry: 'Canada',
                postalCode: 'H4P 2N2',
                streetAddress: '8500 Decarie Blvd, 8th Floor',
                addressLocality: 'Mount Royal, Quebec'
            },
            sameAs: [
                'https://www.facebook.com/RennaiSelfcare',
                'https://www.linkedin.com/company/rennai/',
                'https://www.tiktok.com/@rennaiselfcare',
                'https://www.instagram.com/rennaiselfcare/'
            ]
        }
    }

    return (
        <>
            {['en', 'fr'].map((lang) => (
                <script
                    key={lang}
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(generateSchema(lang), null, 2)
                    }}
                />
            ))}
        </>
    )
}

export default StructuredData
